<template>
  <div class="estadio-body">
    <div id="headerHome" class="col-sm-12 col-md-12 color-bg-1">
      <HeaderHome :name="user.fullname" />
    </div>

    <div class="bloque-ne">
    <div v-if="backgr !== 'No se encontraron coincidencias'">
      <img :src="url + '/get-cabeceras-image/' + backgr.image" alt="pc" class="pc imgx">
      <img :src="url + '/get-cabeceras-image/' + backgr.image_mv" alt="mo" class="mo imgx">
    </div>

        <div class="ne-header">
            <h1>NUESTRO</h1>
            <h1 class="neh-tra">ESTADIO</h1>
            <h6>CIMARRONES DE SONORA</h6>
        </div>

        <p class="txtuno-ne">Nuestra casa el “Héroe de Nacozari” está ubicado en Hermosillo, Sonora.</p>

        <div class="coldos-nef0">
            <div class="bloinfouno-nef1">
              <img src="../assets/icouno-ne.png" alt="">
              <p>Tiene un aforo para 18,000 espectadores, el estadio más grande del estado.</p>
            </div>
          </div>

        <div class="filauno-ne">
          <div class="coluno-nef1">
            <img class="Im1" src="../assets/imagenuno-ne.png" alt="Nuestro Estadio">
            <img class="Im2" src="../assets/Group_57.png" alt="">
          </div>

          <div class="coldos-nef1">
            <div class="bloinfouno-nef1">
              <img src="../assets/icouno-ne.png" alt="">
              <p>Tiene un aforo para 18,000 espectadores, el estadio más grande del estado.</p>
            </div>
          </div>
          
        </div>

        <div class="filados-ne">
          <div class="coluno-nef2">
              <div class="bloinfouno-nef2">
                <img src="../assets/icodos-ne.png" alt="">
                <p>Fue inaugurado en 1985 y remodelado en 2014 para cumplir con todas las exigencias de la Federación Mexicana de Futbol. </p>
              </div>
          </div>
          <div class="coldos-nef2">
              <img class="red-pts" src="../assets/pts-red.png" alt="">
              <img class="Im1" src="../assets/estadio-hdn.png" alt="Estadio Heroe de Nacozari">
              <img class="Im2" src="../assets/estadio-hdn.png" alt="">
          </div>
        </div>

        <div class="filatres-ne">
          <div class="coluno-nef3">
              <div class="bloinfouno-nef3">
                <img src="../assets/icotres-ne.png" alt="">
                <p>Tiene una privilegiada ubicación en la Ciudad con servicio de transporte público, estacionamiento y una extensa explanada para activaciones el día de partido.</p>
              </div>
          </div>
          <div class="coldos-nef3">
            <img src="../assets/jugadore1.png" alt="">
          </div>
          <div class="coltres-nef3">
              <div class="bloinfodos-nef3">
                <img src="../assets/icocuatro-ne.png" alt="">
                <p>Además de futbol, el “Héroe” es un gran lugar para disfrutar de atardeceres maravillosos. Prepara tu cámara y ponte cómodo. </p>
              </div>
          </div>
        </div>

        <div class="filacuatro-ne">
          <div class="coluno-nef4">
              <h3 class="titdist-ne">DISTRIBUCIÓN</h3>
          <div class="coldos-nef00">
            <img src="../assets/distriestadio.png" alt="Distribucion Estadio">
              <img src="../assets/lista-estadio.png" class="distr-estadio-mo" alt="Distribucion Estadio">
          </div>
              <img src="../assets/tablaestadio.png" class="distr-estadio" alt="Distribucion Estadio">
          </div>
          <div class="coldos-nef5">
            <img src="../assets/distriestadio.png" alt="Distribucion Estadio">
          </div>
        </div>

        <div class="filacinco-ne">
          <img src="../assets/estadio1.png" alt="">
          <img src="../assets/estadio2.png" alt="">
          <img src="../assets/estadio3.png" alt="">
        </div>

      </div>

    <div id="footerSection">
      <Footer />
    </div>
  </div>
</template>

<script>
import Footer from "../components/footer";
import HeaderHome from "../components/HeaderHome";
import { mapActions } from "vuex";
let urlpath = require('../global/url')
export default {
    data() {
        return {
            urlpath,
        }
    },
  components: {
    HeaderHome,
    Footer,
  },
  async beforeMount() {
    let token = await this.$store.dispatch("admin/getToken");
    this.$store.dispatch("admin/decode", token);
  }, 
  async created() {
    let payload2 = {
      id: 'Estadio',
      option: "cabeceras/section",
    };
    await this.getCabecerasById(payload2);
  },
  computed: {
    url() {
      return this.$store.getters["main/baseURL"];
    },
    backgr() {
      return this.$store.getters["cabeceras/data"];
    },
    user() {
      return this.$store.getters["admin/getIdentity"];
    },
  },
  methods: {
    ...mapActions("cabeceras", ["getCabecerasById"]),
  },
  /**titulo pagina */
  watch: {
        $route: {
            immediate: true,
            handler(to, from) {
                document.title = to.meta.title || 'Estadio - Cimarrones de Sonora';
            }
        },
    }
};
</script>

<!-- estilos -->
<style scoped>

    .Im1{
        display: block;
    }
    .Im2{
        display: none;
    }
    .coldos-nef0{
        display: none;
    }
    .coldos-nef00{
        display: none;
    }
    .coldos-nef5 img {
    display: block;
}
    .coldos-nef1{
    display: block;
}
img.distr-estadio {
    display: block;
}
    .bloque-ne {
        position: relative;
    }
    .bloque-ne .ne-header {
    width: 100% !important;
    height: 29.895833333333332VW !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    /*background-image: url(../assets/fondoestadio.png) !important;
    background-size: 100%;*/
    background-repeat: no-repeat;
    padding: 7vw 0 4.31vw 0 !important;
    transition: .5s;
    background-position-y: 0vw !important;
    margin-bottom: 0vw !important;
    font-family: 'Rajdhani';
    position: relative;
}

.bloque-ne .ne-header h1 {
    font-size: 6.25VW;
    line-height: 6.375vw;
    background-position: 50%;
    margin-bottom: .1vw;
    color: #b70d1d;
    font-family: Rajdhani;
}
.bloque-ne .neh-tra {
    -webkit-text-stroke: 2px #fff;
    color: transparent!important;
    background-color: #032a4b;
    padding: .7vw 1.25vw;
    margin-left: 5vw;
}
.bloque-ne .ne-header h6 {
    font-size: .938vw;
    line-height: 110.49%;
    letter-spacing: .31em;
    padding-left: 0.5vw;
    text-align: center;   
     color: #fff;
    font-family: Rajdhani;
    padding-top: 2vw;
}

p.txtuno-ne {
    text-align: center;
    color: #032a4b;
    font-family: 'Rajdhani';
    font-weight: 700;
    width: 23%;
    margin: auto;
    font-size: 1.042vw;
    padding: 9.114583333333332VW 0;
}
.filauno-ne {
    display: flex;
}
.coluno-nef1 {
    width: 48.69791666666667VW;
}
.coldos-nef1 {
    width: 51.302083333333336VW;
    padding-left: 11.302083333333334VW;
    padding-top: 15vw;
}
.coluno-nef1 img {
    width: 44.27083333333333VW;
    float: right;
    height: 34.270833333333336VW;
}
.bloinfouno-nef1, .bloinfouno-nef2, .bloinfouno-nef3, .bloinfodos-nef3 {
    display: flex;
}
.bloinfouno-nef1 img {
    width: 5.052083333333333VW;
    height: 5.052083333333333VW;
}
.bloinfouno-nef1 p {
    font-size: 0.9375VW;
    color: #686868;
    line-height: 1.875vw;
    width: 42%;
    margin-left: 3vw;
    margin-top: -.30vw;
}
.filados-ne {
    display: flex;
}
.coldos-nef2 {
    width: 48.69791666666667VW;
    height: auto;
}
.coluno-nef2 {
    width: 51.302083333333336VW;
    padding-left: 11.302083333333334VW;
    padding-top: 15vw;
}
.coldos-nef2 img {
    width: 39.427083333333336VW;
    float: left;
    margin-top: 3.5vw;
    margin-left: 1.3vw;
    height: 27.604vw;
}
.coldos-nef2 img.red-pts {
    width: 2.865vw!important;
    height: 10.26vw!important;
    position: absolute;
    margin-left: 0;
    float: none!important;
    margin-top: 0!Important;
    display: block;
}
.bloinfouno-nef2 img {
    width: 5.416666666666667VW;
    height: 3.5416666666666665VW;
}
.bloinfouno-nef2 p {
    font-size: 0.9375VW;
    color: #686868;
    line-height: 1.875vw;
    width: 43%;
    margin-left: 3vw;
    margin-top: -.30vw;
}
.filatres-ne {
    display: flex;
    padding-top: 6.302083333333333VW;
}
.coluno-nef3{
    width: 31.822916666666668VW;
    padding-top: 20vw;
    padding-left: 11vw;
}
.coldos-nef3 {
    width: 38.854166666666664VW;
    margin-top: 0;
    margin-left: 0vw;
    display: flex;
    justify-content: center;
    align-items: center;
}
.coltres-nef3 {
    width: 29.322916666666664VW;
    padding-top: 20vw;
    margin-left: 0vw;
}
.coldos-nef3 img {
    width: 32.583vw;
    height: auto;
}
.bloque-ne {
    background-image: url('../assets/waves.png');
    background-size: 100%;
    background-repeat: no-repeat;
    background-position-y: 115vw;
}
.bloinfouno-nef3 img {
    width: 4.322916666666667VW;
    height: 4.895833333333333VW;
}
.bloinfouno-nef3 p {
    font-size: 0.9375VW;
    color: #686868;
    line-height: 1.875vw;
    width: 59%;
    margin-left: 3vw;
    margin-top: -.30vw;
}

.bloinfodos-nef3 img {
    width: 5.833333333333333VW;
    height: 4.010416666666667VW;
}
.bloinfodos-nef3 p {
    font-size: 0.9375VW;
    color: #686868;
    line-height: 1.875vw;
    width: 38%;
    margin-left: 3vw;
    margin-top: -.30vw;
}
.filacuatro-ne {
    display: flex;
    padding-top: 10vw;
    padding-bottom: 5vw;
}
.coluno-nef4 {
    padding-left: 7.968749999999999VW;
    width: 51.041666666666664VW;
}
.coluno-nef4 img{
    width: 41.041666666666664VW;
    height: 22.760416666666668VW;
}
.coldos-nef4 img{
    width: 42.083333333333336VW;
    height: 38.90625VW;
}
.coldos-nef5 img{
    width: 42.083333333333336VW;
    height: 38.90625VW;
}
.titdist-ne {
    color: #ffffff;
    background-color: #B70D1D;
    padding: 2vw 1vw;
    width: 55%;
    text-align: center;
    font-size: 2.864583333333333VW;
    font-family: Rajdhani;
    margin-bottom: 5vw;
}
.filacinco-ne {
    display: flex;
    padding-top: 5vw;
}
.filacinco-ne img{
    width: 33.33% !important;
}




img.pc.imgx {
  display: block;
}

img.mo.imgx {
  display: none;
}


@media (max-width: 768px ) {
  img.pc.imgx {
    display: none;
  }

  img.mo.imgx {
    top: 21vw;
    display: block;
    height: 51.208vw;
  }

.bloque-ne .ne-header {
    width: 100%;
    height: 88VW;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    /*background-image: url('../assets/Fondo_Slide.png');
    background-size: 100%;*/
    background-repeat: no-repeat;
    /* padding: 7vw 0 4.31vw 0; */
    transition: .5s;
    margin-bottom: 8vw;
    background-position-y: 0.9vw;
    padding-top: 8vw;
}

.bloque-ne .ne-header h6 {
    color: #fff;
    font-family: Rajdhani;
}
.bloque-ne .ne-header h1 {
    color: #b70d1d;
    font-family: Rajdhani;
}

.bloque-ne .neh-tra {
    -webkit-text-stroke: 0.1vw #fff;
    color: transparent!important;
    background-color: #032a4b;
    padding: 0.7vw 1.25vw;
    margin-left: 5vw;
}
p.txtuno-ne {
    text-align: center;
    color: #032a4b;
    font-family: 'Rajdhani';
    padding-top: 9.114583333333332VW;
    width: 76%;
    margin: auto;
    font-size: 3.865vw;
}

.coldos-nef1{
    display: none;
}

.coldos-nef0{
    margin-top: 13.768115942028986VW;
    margin-left: 17.632850241545896VW;
}

.bloinfouno-nef1{
    display: flex;
}

.bloinfouno-nef1 img {
    width: 17.391304347826086VW;
    height: 17.391304347826086VW;
}

.bloinfouno-nef1 p {
    font-size: 2.898550724637681VW;
    color: #686868;
    line-height: 5.314009661835748VW;
    width: 46%;
    margin-left: 7.729468599033816VW;
    margin-top: -0.3vw;
}

.coluno-nef1 {
    width: 93.69791666666667VW;
}

.coluno-nef1 img {
    width: 94.463768VW;
    float: right;
    height: 72.463768VW;
}

.bloinfouno-nef2{
    display: flex;
}

.coluno-nef2 {
    width: 81.302083333333336VW;
    padding-left: 11.302083333333334VW;
    padding-top: 15vw;
    display: flex;
}


.bloinfouno-nef2 img {
    width: 19.565217391304348VW;
    height: 12.80193236714976VW;
}

.bloinfouno-nef2 p {
    font-size: 2.898550724637681VW;
    color: #686868;
    line-height: 5.314009661835748VW;
    width: 43vw;
    margin-left:8.695652173913043VW;
    margin-top: -0.3vw;
}

.coldos-nef2 {
    width: 100%;
    height: auto;
    text-align: -webkit-center;   
    display: flex;
    justify-content: center;
}

.coldos-nef2 img {
    width: 81.64251207729468VW;
    margin-top: -0.5vw;
    height: 51.20772946859904VW;
}



.filatres-ne {
    display: block;
    padding-top: 6.302083333333333VW;
}

.coluno-nef3 {
    width: 92.822916666666668VW;
    padding-top: 20vw;
    padding-left: 23.18840579710145VW;
}

.bloinfouno-nef3 {
    display: flex;
}

.bloinfouno-nef3 img {
    width: 14.734299516908212VW;
    height: 16.666666666666664VW;
}

.bloinfouno-nef3 p {
    font-size: 2.898550724637681VW;
    color: #686868;
    line-height: 5.314009661835748VW;
    width: 39vw;
    margin-left: 10.628019323671497VW;
    margin-top: -0.3vw;
}

.coldos-nef3 {
    width: 100%;
}

.coldos-nef3 img {
    width: 95.068vw;
    height: auto;
}

.coltres-nef3 {
    width: 66.322916666666664VW;
    padding-top: 20vw;
    margin-left: 23.18840579710145VW;
}

.bloinfodos-nef3 {
    display: flex;
}

.bloinfodos-nef3 img {
    width: 19.32367149758454VW;
    height: 13.285024154589372VW;
}

.bloinfodos-nef3 p {
    font-size: 2.898550724637681VW;
    color: #686868;
    line-height: 5.314009661835748VW;
    width: 51%;
    margin-left: 8.212560386473431VW;
    margin-top: -0.3vw;
}



.filacuatro-ne {
    display: flex;
    padding-top: 10vw;
    padding-bottom: 5vw;
}

.coluno-nef4 {
    text-align: -webkit-center;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.coldos-nef00 {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.titdist-ne {
    color: #ffffff;
    background-color: #B70D1D;
    padding: 5vw 3vw;
    width: 75%;
    text-align: center;
    font-size: 8.695652173913043VW;
    font-family: Rajdhani;
    margin-bottom: 5vw;
}

.coluno-nef4 img {
    width: 90.647343VW;
    height: 65.884058VW;
}

.coldos-nef5 img {
    display: none;
}

.coldos-nef00 img {
    width: 88.64734299516908VW;
    height: 81.88405797101449VW;
}


.filacinco-ne{
    display: block;
}

.filacinco-ne img[data-v-1027ab76] {
    width: 100%;
}

.Im1{
    display: none;
}
img.distr-estadio {
    display: none;
}
img.distr-estadio-mo {
    height: 135.266vw!important;
    width: 73.671vw!important;
    margin: 25.362vw 0;
}.coldos-nef2 img.red-pts{
    display: none;
}



}


</style>